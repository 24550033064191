import { useRef, useEffect, useState, useContext, useReducer, useDeferredValue, useCallback } from "react";

import Icon from "../Icon";

import { AppContext, MapContext } from "../context";
import { makeFieldInfo, useFieldInfo } from "../helpers";
import { recalculateCategories as recalculateCategoriesHelper } from "./helpers";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import bounds from "../../json/bounds.json"

const polygonLineColor = '#E3E3E3',
      polygonActiveLineColor = '#000000',
      polygonFillOpacity = 0.6,
      locationBoundsColor = '#0000FF';

const layerOutlineDefaults = {
        'type': 'line',
        'layout': {},
        'paint': {
          'line-color': polygonLineColor,
          'line-width': 1
        },
        'filter': ["in","fips",""]
      };

const layerHighlightDefaults = {
        'type': 'line',
        'layout': {},
        'paint': {
          'line-color': polygonActiveLineColor,
          'line-width': 2
        },
        'filter': ["in","fips",""]
      };

const colorSetsObject = {
  red:    ['#f0f2f1','#cfd1d0','#ffbbba','#ff7372','#fe0000'],
  green:  ['#edf8fb','#b2e2e2','#66c2a4','#2ca25f','#006d2c'],
  blue:   ['#eff3ff','#bdd7e7','#6baed6','#3182bd','#08519c'],
  pink:   ['#f1eef6','#d7b5d8','#df65b0','#dd1c77','#980043'],
  yellow: ['#feedde','#fdbe85','#fd8d3c','#e6550d','#a63603'],
};

const colorSets = Object.values( colorSetsObject );

var _lockedFips = [];

// const numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 } ).format;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const zeroFill = function( input ) {
  const code = Number( input );
  let str = `${code}`;

  let n = str.length,
      fill = 0;

  if ( code < 100000 ) {
    // zcta 5: 35243 and maybe county id
    fill = 5;
  }
  else if ( code < 10000000 ) {
    // place 7: 0158848
    fill = 7;
  }
  else if ( code < 100000000000 ) {
    // tract 11: 01073012302
    fill = 11;
  }

  for( let i = n; i < fill; i++ ) {
    str = '0' + str;
  }

  return str;
}

const Wrapper = ({ 
    config = {},
    setMap,
  }) => {

  const {
    fields: allFields,
    statsData: data,
    location,
    level,
    levels,
    lockedFips,
    setLockedFips,
    getHash
  } = useContext( AppContext );

  const {
    dispatch: mapDispatch,
    mask,
    field,
    fips,
    recenter,
    reload
  } = useContext( MapContext );


  const mapContainer = useRef( null ),
        map = useRef( null ),
        colorsDialog = useRef( null );

  const [dataLevel,setDataLevel] = useState( null );
  const [loading,setLoading] = useState( 0 );
  const [loaded,setLoaded] = useState( false );
  const [locations,setLocations] = useState([]);
  const [choropleth,setChoropleth] = useState( null );
  const [canUpdateMapBounds,setCanUpdateMapBounds] = useState( { can: true } );
  const [legend,setLegend] = useState( null );
  const [legendShown,setLegendShown] = useState( true ); 
  const [zoom,setZoom] = useState( null );
  const [mapNotice,setMapNotice] = useState( null );

  const [myColorSets,setMyColorSets] = useState( 
    JSON.parse( 
      localStorage?.getItem('colorSets') ?? JSON.stringify( [ colorSets[0] ] )
    ) );
  const [colorSelector,setColorSelector] = useState( false )

  const fieldInfo = useFieldInfo();

  function onChoroplethChange(){
    getHash();
  }

  function setMapLayerFitler( layer_id, value = '' ) {
    const values = Array.isArray( value ) ? [...value] : [ value ];

    const layer = map.current.getLayer( layer_id );
    const fields = map.current.getSource( layer.source ).vectorLayers.find( vl => vl.source_name === layer.sourceLayer ).fields;

    const field = fields?.fips ? 'fips' : 'spatial_id'; 

    if ( ! values ) {
      map.current.setFilter( layer_id, ['in', field, '' ] );
    }
    else {
      const filter = [ 'in', field, ...[...values, ...values.map( zeroFill )] ];
      map.current.setFilter( layer_id, filter );
    }
  }

  useEffect(() => {

    if (map.current) return; // initialize map only once

    const mapConfig = {
      ...config, 
      container: mapContainer.current,
      style: 'mapbox://styles/jmc2/clsk7p57k021d01r4g0d76vpy',      
      center: { lat: 33.5211664, lng: -86.8289229 },
      zoom: 8,
      minZoom: 5,
      maxZoom: 13,
      attributionControl: false
    }

    const mb = new mapboxgl.Map( mapConfig );

    mb.addControl(new mapboxgl.NavigationControl({
      showCompass: false
    }), 'top-left');

    // mb.addControl(new MapboxExportControl({accessToken:process.env.REACT_APP_MAPBOX_API_KEY}), 'top-right');

    // load sources
    // add basic layers - outlines and highlights
    mb.once('style.load',function(){
      mb.addSource('tract', {
        "type": "vector",
        "url": "mapbox://jmc2.5maenj1n"
      });

      mb.addSource('county', {
        "type": "vector",
        "url": "mapbox://jmc2.an15xl8j"
      });

      mb.addSource('place', {
        "type": "vector",
        "url": "mapbox://jmc2.335rxsgu"
      });

      mb.addSource('zcta', {
        "type": "vector",
        "url": "mapbox://jmc2.8j5pt7nm"
      });

      // tracts
      mb.addLayer({ ...layerOutlineDefaults, ...{
        'id': 'tract_outline',
        'source': 'tract',
        'source-layer': 'Birmingham_Tracts-4tmax2',
      }}, 'road-label-simple');

      mb.addLayer({ ...layerHighlightDefaults, ...{
        'id': 'tract_highlight',
        'source': 'tract',
        'source-layer': 'Birmingham_Tracts-4tmax2',
      }}); 

      // counties
      mb.addLayer({ ...layerOutlineDefaults, ...{
        'id': 'county_outline',
        'source': 'county',
        'source-layer': '2018_us_county_5m-04j4xm',
      }}, 'road-label-simple');

      mb.addLayer({ ...layerHighlightDefaults, ...{
        'id': 'county_highlight',
        'source': 'county',
        'source-layer': '2018_us_county_5m-04j4xm',
      }});
      
      // places
      mb.addLayer({ ...layerOutlineDefaults, ...{
        'id': 'place_outline',
        'source': 'place',
        'source-layer': 'Birmingham_Places-8c9s7x',
      }}, 'road-label-simple');

      mb.addLayer({ ...layerHighlightDefaults, ...{
        'id': 'place_highlight',
        'source': 'place',
        'source-layer': 'Birmingham_Places-8c9s7x',
      }});

      // ZIP codes
      mb.addLayer({ ...layerOutlineDefaults, ...{
        'id': 'zcta_outline',
        'source': 'zcta',
        'source-layer': 'Birmingham-ZCTA-2c1icn',
      }}, 'road-label-simple');

      mb.addLayer({ ...layerHighlightDefaults, ...{
        'id': 'zcta_highlight',
        'source': 'zcta',
        'source-layer': 'Birmingham-ZCTA-2c1icn',
      }});


      mb.on('zoomend', function(e){
        setZoom( map.current.getZoom() );
      });


      setMap( mb );
    });

    map.current = mb;

    // console.log( 'setting moveend event' );
    map.current.on('moveend', updateMapBounds );
    map.current.on('zoomend', function(){
      mapDispatch({ type: 'set-zoom', value: map.current.getZoom() });
    });

    /*
    map.current.on('render', function(){
      console.log( "Mapbox render event" );
    });

    map.current.on('sourcedata', function(){
      console.log( "sourcedata" );
    });
    */

    map.current.on('idle', function(){
      // console.log( "MapBox finished rendering")
      setLoading( 0 );
    });
   

    window.mb = map.current;
  });    


  useEffect(() => {

    let newChoropleth;
    if ( localStorage && localStorage.getItem('blocks@' + field + '/' + level) ) {
      newChoropleth = JSON.parse( localStorage.getItem('blocks@' + field + '/' + level) )
      // console.log( "using choropleth from Local Storage for " + 'blocks@' + field + '/' + level, JSON.stringify( newChoropleth) );
    }
    // fallback for old way
    else if ( localStorage && localStorage.getItem('blocks@' + field ) ) {
      newChoropleth = JSON.parse( localStorage.getItem('blocks@' + field ) )
      // console.log( "using choropleth from old Local Storage for " + 'blocks@' + field + '/' + level, JSON.stringify( newChoropleth) );
    }
    else {
      console.log( "Creating from default");

      // create from default
      const theField = allFields.find( f => f.name === field );

      let _colorSet = colorSets[1];
      
      if( /^1/.test( theField?.category_id ) ) {
        _colorSet = colorSets[0];
      }
      else if( /^2/.test( theField?.category_id )
               || /^3/.test( theField?.category_id )
               || /^4/.test( theField?.category_id ) 
               || /^6/.test( theField?.category_id ) ) {
        _colorSet = colorSets[4];
      }
      else if( /^5/.test( theField?.category_id ) ) {
        _colorSet = colorSets[0];
      }
      else if( /^7/.test( theField?.category_id ) ) {
        _colorSet = colorSets[1];
      }

      newChoropleth = { 
        title: "", 
        method: "quantiles", 
        isStatic: true, 
        blocks: _colorSet.map( color => ({ color }) ) 
      };
      
      // console.log( "Default choropleth for " + 'blocks@' + field + '/' + level, JSON.stringify( newChoropleth) );   

      localStorage.setItem( 'blocks@' + field + '/' + level, JSON.stringify(newChoropleth) );
    }

    if( true !== newChoropleth.isStatic ) {
      // console.log( "Recalculating on data or field change and choropleth is not static");
      newChoropleth = recalculateCategories( newChoropleth, false );
    }
    else {
      // check if there are blocks without min and max
      const blocksWithoutMinMax = newChoropleth.blocks.filter( b => {
        return typeof b.min === 'undefined' || 
                typeof b.max === "undefined" || 
                isNaN(b.min) ||
                isNaN(b.max) ||
                !Number.isFinite(+b.min) ||
                !Number.isFinite(+b.max);
      });
      if( blocksWithoutMinMax.length || ( level !== dataLevel ) ) {
        // console.log( 'Blocks without Min or Max', JSON.stringify(blocksWithoutMinMax) );
        // console.log( `Recalculating on data or field change and level mismatch ${level} ? ${dataLevel} or has blocks without min/max ${blocksWithoutMinMax.length}`);

        newChoropleth = recalculateCategories( newChoropleth, false );
        localStorage.setItem( 'blocks@' + field + '/' + level, JSON.stringify(newChoropleth) );
      }
    }

    setChoropleth( newChoropleth );
    setDataLevel( level );


    // we need to update current choropleth
    // otherwise it doesn't update colors on the map
    choropleth?.blocks?.splice( 0 );
    choropleth?.blocks?.push( ...newChoropleth.blocks );    

    mapDispatch({ type: 'set-reload', value: reload + 1 });
  }, [data, field]);
  

  useEffect(() => {
    if( !map.current ) {
      return;
    }
    // console.log( "MB place polygons", choropleth, reload, data, field, location)    

    setLoading( 1 );
    if( map.current.loaded() ) {
      placePolygons();
    }
    else {
      map.current.once('load', placePolygons);
    }   
  }, [map, choropleth, location]); 

  useEffect(() => {
    if( !map || !map.current || !reload ) {
      return;
    }

    map.current.resize();
  }, [ reload ]);

  useEffect( () => {
    if( !map?.current || !loaded ) {
      return;
    } 

    _lockedFips = lockedFips;

    updateMapHighlight();
  }, [ fips, lockedFips ]);

  useEffect( () => {
    if( !map.current || !loaded ) {
      return;
    }

    const src = getSourceByLevel();
    
    const list = Object.keys( levels );

    for( let i = 0; i < list.length; i++ ) {
      const s = list[i];

      if( s !== src ) {
        setMapLayerFitler( s + '_outline' );
        setMapLayerFitler( s + '_highlight' );
      }
    }

    const layers = map.current.getStyle().layers.filter( l => l.id.match( /^g[-\d]+$/ ));
    layers.forEach( l => map.current.removeLayer( l.id ) );  
  }, [level]);


  useEffect( () => {
    if( !map.current ) {
      return;
    }

    const src = getSourceByLevel();

    const source = map.current.getSource( src );
    if( !source ) {
      return;
    }

    const min = source.minzoom,
          max = source.maxzoom;
    
    if( zoom < min ) {
      const avail = [];
      for( let src in levels ) {
        const source = map.current.getSource( src );
        if( !source ) {
          continue;
        }

        if( zoom >= source.minzoom && zoom <= source.maxzoom ) {
          avail.push({
            source: src,
            label: levels[src]
          });
        }

      }

      setMapNotice( 
        <div className="flex gap-2">
          <div className="text-orange-500 text-xl">
            <Icon name="circle-exclamation" />
          </div>

          <div>
            <p>Selected data level does not support selected zoom level</p>
            <p>Please zoom in or select different data level</p>

            { avail.length && <ul className="list-disc ml-4">{ avail.map( s => <li key={s.source}>{ s.label }</li>)}</ul>}
          </div>
        </div>
      );
    }
    /*
    else if ( zoom > max ) {
      setMapNotice( 
        <>
          <p>Zoom out </p>
        </> 
      );
    }
    */
    else if( mapNotice ) {
      setMapNotice( null );
    }

  }, [zoom])

  useEffect( () => {
    if( typeof onChoroplethChange != 'function' ) {
      return;
    }

    onChoroplethChange();
  }, [choropleth] );

  useEffect( () => {
    updateMapBounds();
  }, [ location ]);

  function format( number ){
    switch( fieldInfo?.unit ) {
      case "%":
        return number + '%';
        return Math.round( number * 1000 ) / 10 + '%';

      case "$":
        return new Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format( number );

      default:
        if( fieldInfo?.unit ) {
          return number + fieldInfo?.unit;
        }
        else if( /^[-]{0,1}\d+$/.test( number ) ){
          return new Intl.NumberFormat( 'en-US', {}).format( number );
        }
        else if( /^[-]{0,1}\d+(\.\d+)$/.test( number ) ){
          return new Intl.NumberFormat( 'en-US', { 
                                  minimumFractionDigits: 2, 
                                  maximumFractionDigits: 2 
                              }).format( number );
        }
        else {
          return number;
        }
    }

  }

  function getSourceByLevel( lvl = level) {
    return lvl;
  }

  function polygonHoverHandler(e){
    if( !e?.features?.length ) {
      return;
    }

    const fips_code = Number( e?.features[0]?.properties?.fips || e?.features[0]?.properties?.spatial_id );
    
    if( fips_code ) {
      // const src = getSourceByLevel();
      // map.current.setFilter( src + '_highlight', ["==","fips", fips_code ] )
      // updateMapHighlight( fips_code );
      updateMapFips( fips_code );
    }
  } // polygonHoverHandler

  function polygonLeaveHandler(e){
    mapDispatch({ type: 'set', prop: "fips", value: null });
  } // polygonHoverHandler  

  function polygonClickHandler(e){

    if( !e?.features?.length ) {
      return;
    }

    const fips_code = Number( e?.features[0]?.properties?.fips || e?.features[0]?.properties?.spatial_id );

    if( fips_code ) {
      const newLocked = [..._lockedFips];
      if( newLocked.includes( fips_code ) ) {
        newLocked.splice( newLocked.indexOf( fips_code ), 1 );
      }
      else {
        newLocked.push( fips_code );
      }
      
      setLockedFips( newLocked );
    }
  } // polygonClickHandler

  function placePolygons(){
    const zones =  data.fipsShown; // data.fips_codes;
    if( !zones?.length ) {
      // console.log( "No zones" );
      // clearPolygons( [] );
      return;
    }

    // console.log( "Started placing polygons" );
    const newBounds = new mapboxgl.LngLatBounds();
    const qty = zones.length;

    // group by layer
    const grouped = {};

    for( let i = 0; i < qty; i++ ) {
      const zone = zones[i];

      if( !zone ) {
        continue;
      }

      const entry = data?.entries[ zone ];
      if( !entry ) {
        // console.log( "MB - Zone not found", zone );
      }

      let block;
      if( entry ) {
        let value = entry[ field ]?.value;
        if( fieldInfo?.unit === '%' ) {
          value = Math.round( value * 10000 ) / 100;
        }
        if( value || value === 0) {
          block = choropleth?.blocks?.find( b => value >= b.min && value <= b.max );

          if( !block && choropleth?.blocks?.length ) {
            // check if it's less then first min 
            if( value < choropleth.blocks[0].min ) {
              block = choropleth.blocks[0];
            }
            else if( value >= choropleth.blocks[ choropleth.blocks.length - 1 ].max ) {
              block = choropleth.blocks[ choropleth.blocks.length - 1 ];
            }
          }
        }
      }

      const blockIndex = ( block && choropleth?.blocks?.length ) ? choropleth.blocks.indexOf( block ) : -1;

      if( blockIndex === -1 ) {
        // console.log( "Block Index Not Found", entry.fips_code );
      }

      if( typeof grouped[ blockIndex ] === "undefined" ) {
        grouped[ blockIndex ] = [];
      }
      grouped[ blockIndex ].push( entry.fips_code );


      if( bounds && bounds[zone] ) {
        newBounds.extend( bounds[zone] )
      }
    }

    const src = getSourceByLevel(); 
    const blocksQty = choropleth?.blocks?.length ?? 0;   

    for( let idx = 0; idx < blocksQty; idx++ ) {
      const gid = 'g' + idx;

      let groupFillLayer =  map.current.getLayer(gid);
      if( !groupFillLayer ) {
        groupFillLayer = map.current.addLayer({
          'id': gid,
          'type': 'fill',
          'source': src,
          'source-layer': map.current.getLayer( src + '_outline' ).sourceLayer,
          'layout': {},
          'paint': {
            'fill-opacity': polygonFillOpacity,
            'fill-antialias': false,
          }
        }, src + '_outline' );

        map.current.on('mousemove', gid, polygonHoverHandler );       
        map.current.on('mouseleave', gid, polygonLeaveHandler );       
        map.current.on('click', gid, polygonClickHandler );
      }

      // console.log( "Setting polygon fill", gid, idx, choropleth.blocks[idx].color );
      map.current.setPaintProperty( gid, 'fill-color',  idx > -1 ? choropleth.blocks[idx].color : 'transparent' );

      if( grouped[idx] && grouped[idx].length ) {
        setMapLayerFitler( gid, grouped[idx] );
      }
      else {
        setMapLayerFitler( gid );

      }
    } // end for grouped  

    for( let n = blocksQty; n < 99; n++ ) {
      if( map.current.getLayer( 'g' + n ) ) {
        setMapLayerFitler( 'g' + n );
      }
    }
    
    // outline zones
    setMapLayerFitler( src + '_outline', zones );

    const locationBounds = getLocationBounds();
    // console.log( "Location", location, "Mask", mask );
    // console.log( "Location Bounds", locationBounds );
    if( locationBounds && locationBounds.length ) {
      locationBounds.forEach( b => {
        newBounds.extend( b );
      });
    }

    // console.log( "disable bounds update" );
    setCanUpdateMapBounds( { can: false } );
    canUpdateMapBounds.can = false;

    if( ( !loaded || recenter ) && zones.length && newBounds?.getEast() ) {
      map.current.fitBounds( newBounds, { padding: 16 } );
      mapDispatch({ type: 'set-recenter', value: false });
    }

    if( !loaded ) {
      canUpdateMapBounds.can = true;
      updateMapBounds();
      setLoaded( true );
    }

    // console.log( "Polygons added to map" );
    setLoading( 2 );
  }

  function updateMapFips( c ){
    const code = Number( c );

    if( code !== fips ) {
      mapDispatch({ type: 'set', prop: "fips", value: code });
    }
  }

  function updateMapHighlight( additional = null ){
    const src = getSourceByLevel();

    let list = [];
    if( fips) {
      list.push( fips );
    }
    if( additional ) {
      list.push( additional );
    }
    if( lockedFips?.length ) {
      list = [ ...lockedFips, ...list ];
    }

    setMapLayerFitler( src + '_highlight', list );
  }

  function getLocationBounds(){

    if( !map.current ) {
      return;
    }

    // remove old bounds
    if( locations.length ) {
      for( let i = 0; i < locations.length; i++ ) {
        if( !locations[i] ) {
          continue;
        }

        if( map.current.getLayer( locations[i] + '_outline' ) ) {
          map.current.removeLayer( locations[i] + '_outline');
        }
      }
    }

    if( !mask || !location ) {
      return [];
    }

    const newLocations = [];

    const id = 'l' + location;
       
    // Add a black outline around the polygon.
    let src;
    if( +location > 99999 ) {
      src = 'place';
    }
    else {
      src = 'county';
    }

    let layer = map.current.getLayer( id + '_outline' );
    if( !layer ) {
      layer = map.current.addLayer({
        'id': id + '_outline',
        'type': 'line',
        'source': src,
        'source-layer': map.current.getLayer( src + '_outline' ).sourceLayer,
        'layout': {},
        'paint': {
          'line-color': locationBoundsColor,
          'line-width': 1
        }
      });
    }

    setMapLayerFitler( id + '_outline', location );

    if( id ) {
      newLocations.push( id );
    }

    setLocations( newLocations );
    return bounds && bounds[location] ? bounds[location] : [];
  }

  function updateMapBounds(){
    // console.log( "Should Update Bounds", canUpdateMapBounds );
    if( !canUpdateMapBounds.can ) {
      // console.log( "activating bounds update" );
      setCanUpdateMapBounds( { can: true } );
      canUpdateMapBounds.can = true;      
      return;
    }

    // console.log( "updating bounds");
    const bounds = map.current.getBounds();
    
    const boundsString = 
    [
      bounds.getNorthWest().toArray().join(" "), 
      bounds.getNorthEast().toArray().join(" "), 
      bounds.getSouthEast().toArray().join(" "),
      bounds.getSouthWest().toArray().join(" "), 
      bounds.getNorthWest().toArray().join(" ")
    ].join(',');

    // console.log( boundsString );

    mapDispatch({ type: 'set-bounds', value: boundsString });
  } // updateMapBounds

  function recalculateCategories( choro = null ){
    const info = makeFieldInfo( field, allFields );
    const newChoro = recalculateCategoriesHelper( choro, info, data );
    return newChoro;
  } // recalculateCategories

  function setChoroplethColor( set ) {
    const newLegend = JSON.parse( JSON.stringify(legend) );
    set.forEach( (c,i) => {
      if( newLegend.blocks[i] ) {
        newLegend.blocks[i].color = c;
      }
    });
    if( newLegend.blocks.length > set.length ) {
      for( let i = set.length; i < newLegend.blocks.length; i++ ) {
        newLegend.blocks[i].color = set[ set.length - 1 ];
      }
    }

    setLegend( newLegend );
    setColorSelector( false );
  } // setChoroplethColor

 
  function rgbToHex(r, g, b) {
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  

  // console.log( data );
  const mapOffset = mapContainer?.current?.getBoundingClientRect().top ?? 0;

  return (
    <>
      <div className="relative" style={{ height: `calc( 100vh - ${mapOffset}px - 1.5rem )` }} >
        <div ref={mapContainer} id="map"></div>

        { field && 
          <div
            id="map-legend"  
            className="absolute right-4 bottom-4">
            <div className="flex flex-col gap-1 p-3 min-w-[15rem] bg-white border shadow-sm shadow-gray-500 rounded-md">
              <button onClick={ () => { setLegendShown(!legendShown) }}
                className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 border w-4 leading-4 box-content rounded-full  bg-white text-gray-500
                ">
                <Icon name={ legendShown ? "caret-down" : "caret-up" } />
              </button>

              <div className="flex gap-2 items-start justify-between">
                <pre className="font-semibold text-xs">{ choropleth?.title || fieldInfo?.label || fieldInfo?.source_label || field }</pre>

                <button onClick={ () => setLegend( JSON.parse( JSON.stringify( choropleth ) ) ) }>
                  <Icon name="pen-to-square"></Icon>
                </button>
              </div>

              { legendShown ? 
              <>
                <div className="text-[10px] leading-tight text-black/70 pb-4 font-normal">
                  By { levels[level] }
                </div>

                <div className="flex flex-col-reverse gap-2 text-[10px] leading-tight font-medium overflow-auto scrollbar">
                { 
                  choropleth?.blocks?.map( (b,i) => {
                    return <div key={i} className="flex gap-2 items-center">
                        <span className="w-4 h-2 rounded-sm" 
                              style={{ backgroundColor: b.color }}></span>
                        
                        { !isNaN(b.min) && !isNaN(b.max) && isFinite(b.min) && isFinite(b.max) && <span className="">
                          { false && !i && <span>under {format(b.max) }</span> }
                          { ( true || ( i && i < choropleth?.blocks?.length - 1 ) ) && <span>{format(b.min)} to {format(b.max)}</span>}
                          { false && i === ( choropleth?.blocks?.length - 1 ) && <span>{format(b.min)}+ </span>}
                        </span> }
                      </div>
                  })
                }
                </div>
              </>
              : <div className="flex flex-row-reverse gap-1 justify-between" style={{ fontSize: "xx-small" }}>
                { 
                  choropleth?.blocks?.map( (b,i) => {
                    return <div key={i} className="flex flex-col gap-0 items-center">
                        <span className="inline-block w-4 h-4 align-middle" 
                              style={{ backgroundColor: b.color }}></span>
                        
                        { !isNaN(b.min) && !isNaN(b.max) && isFinite(b.min) && isFinite(b.max) && <span className="">
                          <span>{format(b.min)}to{format(b.max)}</span>
                        </span> }
                      </div>
                  })
                }
                </div>
              }


              { legend && (
                <div ref={colorsDialog} 
                  className="absolute z-10 -left-8 bottom-0 -translate-x-full 
                                min-w-[17rem] 
                                shadow-sm shadow-gray-500
                                rounded-lg border border-solid border-gray-400 overflow-hidden
                                ">
                  <div className="bg-white flex flex-col">

                    <div className="font-semibold px-6 py-4 border-b border-solid border-gray-400">
                      Edit Legend
                    </div>

                    <div className="
                      flex flex-col gap-4
                      px-6 py-4 max-h-[80vh] overflow-auto scrollbar">
                      <div className="">
                        <div>Title:</div>
                        <div>
                          <textarea type="text"
                            value={ legend?.title ?? '' } 
                            rows={2}
                            className="w-full" 
                            onChange={ (e) => {
                              const newLegend = JSON.parse( JSON.stringify( legend ) )

                              newLegend.title = e.target.value;

                              setLegend( newLegend );
                            }}
                            ></textarea>
                        </div>
                      </div>

                      <div className="">
                        <div>Classification Method:</div>
                        <div>
                          <select value={ legend.method }
                            className="w-full"
                            onChange={ (e) => {
                              legend.method = e.target.value;                           
                              const newLegend = JSON.parse( JSON.stringify( recalculateCategories( legend ) ) );
                              setLegend( newLegend );
                            } }
                            >
                            <option value="quantiles">Quantiles</option>
                            <option value="equalIntervals">Equal Intervals</option>
                          </select>
                        </div>
                      </div>

                      <div className="">
                        <div>Number of categories</div>
                        <div>
                          <input type="number" 
                            min={2}
                            step={1}
                            value={ legend.blocks.length } 
                            className="w-full"                      
                            onChange={ (e) => {
                              const qty = e.target.value;

                              const newLegend = JSON.parse( JSON.stringify(legend) ),
                                    curQty = newLegend.blocks.length;

                              const lastColor = newLegend.blocks[ curQty - 1 ].color;
                              let color = '#' + Math.floor(Math.random()*16777215).toString(16);

                              if( qty > newLegend.blocks.length ) {
                                newLegend.blocks.push( { color: color, min: null, max: null } );
                              }
                              else if( qty < newLegend.blocks.length) {
                                newLegend.blocks.splice(qty);
                              }

                              setLegend( recalculateCategories( newLegend ) );
                              /*
                              if( !newLegend.isStatic ) {
                                setLegend( recalculateCategories( newLegend ) )
                              }
                              else {
                                setLegend( newLegend );
                              }
                              */
                            }} />
                        </div>
                      </div>

                      <div className="relative">
                        <div className="flex gap-2 items-center">
                          <span>Color Scheme</span>
                          <button className="button" onClick={ () => setColorSelector(true) }>Select</button>
                        </div>

                        { colorSelector && 
                          <div className="absolute -translate-y-1/2 border border-sky-950 p-2 shadow-lg shadow-sky-950 w-full bg-white">
                            <div className="flex flex-col gap-2">
                              <div>My Color Sets</div>
                              { myColorSets.map( ( set, i ) => {
                                return (
                                  <div className="flex hover:outline-1 hover:outline hover:outline-blue cursor-pointer" 
                                    key={i}
                                    onClick={ () => setChoroplethColor( set ) }>
                                    { set.map( c => <span key={c} className="w-6 h-6" style={{ backgroundColor: c }}></span> ) }
                                  </div>
                                )
                              }) }
                            </div>

                            <div className="flex flex-col gap-2">
                              <div>Default Color Sets</div>

                              { colorSets.map( ( set, i ) => {
                                return (
                                  <div className="flex hover:outline-1 hover:outline hover:outline-blue cursor-pointer" 
                                    key={i}
                                    onClick={ () => setChoroplethColor( set )}>
                                    { set.map( c => <span key={c} className="w-6 h-6" style={{ backgroundColor: c }}></span> ) }
                                  </div>
                                )
                              }) }
                            </div>

                            <div className="text-right pt-4">
                              <button className="button" onClick={ () => setColorSelector( false ) }>Cancel</button>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="flex flex-col gap-2">
                        <div>Edit individual values and colors</div>

                        <div className="flex items-center gap-2 justify-between">
                          <button onClick={ () => {
                              const isOn = !legend.isStatic;
                              const newLegend = JSON.parse( JSON.stringify( isOn ? legend : recalculateCategories( legend ) ) );
                              newLegend.isStatic = isOn;
                              setLegend( newLegend );
                            }}
                            className="flex items-center gap-2"
                            >
                            <Icon name={ !legend.isStatic ? 'square-check' : 'square' } />

                            <span>Auto-recalculate</span>
                          </button>

                          <button onClick={ () => {
                              const newLegend = recalculateCategories( legend );

                              setLegend( JSON.parse( JSON.stringify( newLegend ) ) );
                            }}>
                            <Icon name="repeat" />
                          </button>
                        </div>

                        <div className="text-sm flex flex-col-reverse gap-2">
                          { legend.blocks.map( ( block, i ) =>  (
                            <div className="flex gap-4 justify-between" key={i}>
                              
                              <div className="">
                                <input type="color"
                                        className="w-12 h-6"
                                        value={ block.color } 
                                        onChange={ (e) => {
                                          const color = e.target.value;

                                          const newLegend = JSON.parse( JSON.stringify( legend ) );

                                          newLegend.blocks[i].color = color;
                                          
                                          setLegend( newLegend );
                                        }}
                                        />
                              </div>

                              <div className="text-xs">{ !!block.min && !isNaN(block.min) && isFinite(block.min) ? block.min : '' }</div>

                              <div className="">
                                <input type="text"
                                        className="w-16 text-xs px-2 py-1"
                                        value={ !!block.max && !isNaN(block.max) && isFinite(block.max) ? block.max : '' } 
                                        readOnly={!legend.isStatic}
                                        onChange={ (e) => {
                                          let max = e.target.value;
                                          
                                          const newLegend = JSON.parse( JSON.stringify( legend ) );

                                          if( !/^[-]{0,1}\d+(\.\d+){0,1}$/.test( max ) ) {
                                            newLegend.blocks[i].max = max;
                                          }
                                          else {
                                            newLegend.blocks[i].max = (+max) !== 0 ? +max : "0";
                                            if( newLegend.blocks[i+1] ) {
                                              let value = +max;
                                              if( field?.step ) {
                                                switch( field.step ){
                                                  case 0.1: 
                                                    value = Math.round( ( value + 0.1 ) * 10 ) / 10;
                                                    break;
                                                  case 0.01:
                                                    value = Math.round( ( value + 0.1 ) * 100 ) / 100;
                                                    break;
                                                  case 1:
                                                    value = Math.round( value + 1 );
                                                    break;
                                                  default:
                                                    value = Math.round( value + (+field.step ) );
                                                }
                                              }
                                              else {
                                                value = +value + 1;
                                              }

                                              newLegend.blocks[i+1].min = value;
                                            }
                                          }
                                          
                                          setLegend( newLegend );
                                        }}
                                        />
                              </div>
                            </div>
                            )
                          ) 
                          }
                        </div>
                      </div>
                      
                      <div className="flex gap-2 items-center justify-end">
                        <button className="button" onClick={ () => { setLegend( null ) } }>Cancel</button>
                        <button className="button button-primary" onClick={ () => {
                          setChoropleth( JSON.parse( JSON.stringify( legend ) ) );
                          localStorage.setItem( 'blocks@' + field + '/' + level, JSON.stringify(legend) );

                          // update colors of other levels
                          Object.keys( levels ).forEach( l => {
                            if( l === level ) {
                              return;
                            }

                            const levelConfigString = localStorage.getItem( 'blocks@' + field + '/' + l );
                            if( !levelConfigString ) {
                              return;
                            }

                            const levelConfig = JSON.parse( levelConfigString );
                            levelConfig.blocks.forEach( (b,i) => {
                              if( legend.blocks.length > i &&  legend.blocks[i].color ) {
                                b.color =  legend.blocks[i].color;
                              }
                            });

                            localStorage.setItem( 'blocks@' + field + '/' + l, JSON.stringify( levelConfig ) );
                          })

                          const lastColorSet = legend.blocks.map( b => b.color );
                          const isInColorSet = myColorSets.find( colors => colors.join(',') === lastColorSet.join(',') );
                          if( !isInColorSet ) {
                            const newMyColorSets = JSON.parse( JSON.stringify( myColorSets ) );
                            newMyColorSets.unshift( lastColorSet );
                            setMyColorSets( newMyColorSets );

                            localStorage.setItem( 'colorSets', JSON.stringify(newMyColorSets) );
                          }
                          
                          setLegend( null );
                        } }>Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}  
            </div>        
          </div>
        }
        

        { 
          mapNotice && 
          <div className="absolute top-4 left-1/2 -translate-x-1/2 py-2 px-4 bg-white border shadow-sm shadow-gray-500 text-xs">{ mapNotice }</div>
        }

        { 
          ( loading || !loaded ) ? 
            <div className="absolute left-1/2 bottom-0 p-2 -translate-x-1/2 text-black bg-slate-100 text-xs">
              { loading === 1 && <span>Processing data...</span> }
              { loading === 2 && <span>Rendering map...</span> }
            </div>
          : null
        }
      </div>
    </>
  )
}

export default Wrapper;