import React, { useState, useEffect, useRef, useReducer } from "react";

import { AppContext, MapContext, ChartsContext } from "./components/context";

import Icon from "./components/Icon";

import Sidebar from "./components/Sidebar";
import MapChartsContainer from "./components/MapChartsContainer";

import { cn, ToggleIcon } from "./components/helpers"; 

import DataTableContainer from "./components/table/DataTableContainer";
import IndexVariableManager from "./components/IndexVariableManager";
import { chartReducer, mapReducer } from "./components/reducer";

const levels = {
  "tract": "Census Tracts",
  "zcta": "ZCTA",
  "place": "Places",
  "county": "County"
};  

const title = process.env.REACT_APP_TITLE;

function App() {
  const [mapState,mapDispatch] = useReducer( mapReducer, {}, () => {
    return {
      field: localStorage?.getItem('mapField') ?? 'population',
      reload: 0,
      recenter: true,
      fips: null,
      mask: localStorage?.getItem('maskLocation') ?? true,
      bounds: null,
      zoom: 8,
      legend: null
    }
  });

  const [chartsState,chartsDispatch] = useReducer( chartReducer, {}, () => {
    return JSON.parse( localStorage?.getItem('charts') ?? '[]' )
  });

  const {
    field:  mapField,
    reload: reloadMap,
    mask:   maskLocation,
    bounds: mapBounds,
  } = mapState;

  const [configLoading,setConfigLoading] = useState( false );
  const [configLoaded,setConfigLoaded] = useState( false );

  // control tabs of the app
  const [showTable,setShowTable] = useState( localStorage?.getItem('showTable') ?? true );
  const [showMap,setShowMap] = useState( localStorage?.getItem('showMap') ?? true );
  const [showChart,setShowChart] = useState( localStorage?.getItem('showChart') ?? false );

  // control whether left columns selector is shown
  const [columnsPanel,setColumnsPanel] = useState( true );

  // control what fields (columns) categories are open
  const [sidebarCategoriesOpen, setSidebarCategoriesOpen] = useState( JSON.parse( localStorage?.getItem('sidebarCategoriesOpen') ) ?? [] );  
  
  // list of columns, added to the table
  const [columns,setColumns] = useState( JSON.parse( localStorage?.getItem('columns') ) ?? [] );

  // list of FIPS codes selected
  const [lockedFips,setLockedFips] = useState( [] );

  // full list of counties
  const [counties,setCounties] = useState([]);

  // full list of places
  const [places,setPlaces] = useState([]);

  // full list of zcta
  const [zcta,setZcta] = useState([]);

  // full list of tracts
  const [tracts,setTracts] = useState([]);

  // full list of categories
  const [categories,setCategories] = useState([]);

  // full list of fields
  const [fields,setFields] = useState([]);

  // processed data from the API
  const [statsData, setStatsData ] = useState([]);
  
  // list of fields to sort by
  const [tableSort,setTableSort] = useState( JSON.parse( localStorage?.getItem('dataSort') ) ?? [{"field": 1, "sort": 'DESC'}] ); 

  // list of fields to filter by 
  const [tableFilter,setTableFilter] = useState(null);

  // share of population to limit table display by
  const [populationThreshold,setPopulationThreshold] = useState(null);

  // level of data: county, tract, zip, place
  const [level,setLevel] = useState( localStorage?.getItem('level') ?? 'tract' );

  // selected location
  const [location,setLocation] = useState( localStorage?.getItem('location') ?? '107000' );

  // search field
  const [search,setSearch] = useState( '' );
  
  // show / hide list of search results
  const [openLocationSuggestions,setOpenLocationSuggestions] = useState( false );

  // list of search results
  const [locationSuggestions,setLocationSuggestions] = useState( [] );

  // list of previous location searches
  const [locationHistory,setLocationHistory] = useState( localStorage?.getItem('locationHistory') ? JSON.parse( localStorage.getItem('locationHistory') ) : [1073,107000] );

  // control for table width
  const [tableWidth,setTableWidth] = useState(50);

  // control for map width
  const [mapWidth,setMapWidth] = useState(50);

  // resizing status field
  const [isResizing,setIsResizing] = useState( false );

  // holds current page url has code
  const [hash,setHash] = useState( window.location.pathname.replace( process.env.PUBLIC_URL, '' ).replace( /^\//, '') );

  // holds list of custom index variables 
  // and their composites
  const [indexVariables, setIndexVariables] = useState( localStorage?.getItem('indexVariables') ? JSON.parse( localStorage.getItem('indexVariables') ).map( iv => { if( !iv.id ) { iv.id = +(new Date); } return iv; }) : [] )

  // custom variable editor control
  const [idxVar,setIdxVar] = useState( null );

  // 
  const [hashBuildActive, setHashBuildActive] = useState( false );
  // 
  const [hashTimeout,setHashTimeout] = useState( [] );
  // hash load aborter
  const [aborter,setAborter] = useState( null );  

  const header = useRef(null);
  const resizeContainer = useRef(null);
  const resizer = useRef(null);

  const defaultFields = [
    { name: 'fipsCode', label: "ID", render: (row) => row.fips_code },
    { name: 'fipsLabel', label: "Name", render: (row) => {
        if( row.fips_label ) {
          return row.fips_label;
        }

        const fips = getFips( row.fips_code );

        return fips?.label ?? row.fips_code;      
      } 
    },
    'population',
  ];
  
  // toggle table column
  function toggleColumn( key, level ) {    
    const defaults = [...defaultFields.map( f => typeof f == 'object' ? f.name : f )];
    let list = [...columns];

    if( level?.key.match( /#all/ ) ) {
      // means all in section
       
      console.log( 'Toggle all', level );
      const _fields = level.fields;

      const notSelected = _fields.filter( f => !columns.includes(f) ).length;
      
      for( let i = 0; i < _fields.length; i++ ) {
        if( notSelected ) {
          if( !list.includes(_fields[i]) ) {
            list.push( _fields[i] );
          }
        }
        else {
          if( list.includes(_fields[i]) ) {
            list.splice( list.indexOf( _fields[i] ), 1 );
          }
        }
      }
    }
    else {
      // means specific 
      if( list.includes(key) ) {
        list.splice( list.indexOf( key ), 1 );

        if( mapField == key ) {
          // change to a different one
          updateMapField( list[ list.length - 1 ] );
        }
      }
      else if ( !defaults.includes(key) ) {
        // remove all filter
        const parent = key.replace( /-*[a-zA-Z]+$/, '' );

        if( parent && list.includes( parent ) ) {
          list.splice( list.indexOf( parent ), 1 );
        }

        list.push( key );

        updateMapField( key );
      }  
    }
    

    setColumns( list );

    if( localStorage ) {
      localStorage.setItem('columns', JSON.stringify(list) );
    }
  } // toggleColumn

  // set field shown on the map
  function updateMapField( field ) {
    const newField = fields.find( f => f.name == field ) ? field : 'population'; 
    mapDispatch({ type: 'set-field', value: newField });
    localStorage.setItem( 'mapField', newField );
  } // updateMapField

  // load system configuration: fields, categories, counties...
  function loadConfig(){

    if( configLoading || configLoaded ) {
      return;
    }

    setConfigLoading( true );

    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'config';
    
    fetch( url.href )
    .then( response => response.json() )
    .then( result => {
      if( !result.status || !result.config ) {
        return failed( result );
      }

      if( result.config.counties ) {
        setCounties( result.config.counties );
      }

      if( result.config.places ) {
        setPlaces( result.config.places );
      }

      if( result.config.zcta ) {
        setZcta( result.config.zcta );
      }

      if( result.config.tracts ) {
        setTracts( result.config.tracts );
      }

      if( result.config.categories ) {
        // 
        const cats = JSON.parse( JSON.stringify( result.config.categories ) );
        cats.push({
          id: 999999,
          icon: 'layer-group',
          label: 'Index Variables',
          name: '_idx_vars',
          parent: null
        });

        if( indexVariables?.length ) {
          const placed = [];
          for( let i = 0; i < indexVariables.length; i++ ) {
            if( placed.includes( indexVariables[i].name ) ) {
              continue;
            }
            placed.push( indexVariables[i].name );

            const indexVariable = {
              id: 1000000 + i,
              icon: null,
              label: indexVariables[i].label,
              name: indexVariables[i].name,
              parent: 999999
            };

            cats.push( indexVariable );
          }
        }

        // console.log( '>> cats', cats );
        setCategories( cats );
      }

      if( result.config.fields ) {
        const configFields = JSON.parse( JSON.stringify( result.config.fields ) );
        const maxId = configFields.reduce( (prev,cur) => {
          if( prev && prev.id > cur.id ) {
            return prev.id;
          }
          return cur.id;
        })

        if( indexVariables?.length ) {
          for( let i = 0; i < indexVariables.length; i++ ) {
            const indexVariable = {
              id: maxId + i + 1,
              category_id: 1000000 + i,
              label: indexVariables[i].label,
              name: indexVariables[i].name,
              step: 0.01
            };

            configFields.push( indexVariable );
          }
        }

        // console.log( '>> fields', configFields );
        setFields( configFields );
      }

      setConfigLoading( false );
    })
    .catch( failed )
    .finally(() => {

    });

    function failed(){
      // console.log( arguments );
    }
  } // loadConfig

  // load configuration for an opened url hash
  function loadHashConfig(){
    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'hash-config';
    url.search = '?uid=' + encodeURIComponent(hash);
    
    fetch( url.href )
    .then( response => response.json() )
    .then( result => {
      if( !result.status || !result.data ) {
        return failed( result );
      } 

      const params = result?.data?.params;      

      if( params.blocks ) {
        for( let f in params.blocks ) {
          localStorage.setItem('blocks@' + f, JSON.stringify( params.blocks[f] ) );
        }

        mapDispatch({ type: 'set-recenter', value: true });
      }

      if( params.mapBounds ) {
        localStorage.setItem( 'mapBounds', JSON.stringify( params.mapBounds ) );
        mapDispatch({ type: 'set-bounds', value: params.mapBounds });
      }

      if( params.columns ) {
        if( params.columns.includes('population') ) {
          params.columns.splice( params.columns.indexOf('population'), 1 );
        }

        localStorage.setItem( 'columns', JSON.stringify( params.columns ) );
        setColumns( params.columns );
      }

      if( params.mapField ) {
        localStorage.setItem( 'mapField', params.mapField );
        mapDispatch({ type: 'set-field', value: params.mapField });
      }

      if( params.lockedFips ) {
        localStorage.setItem( 'lockedFips', JSON.stringify( params.lockedFips ) );
        setLockedFips( params.lockedFips )
      }

      if( params.tableSort ) {
        localStorage.setItem( 'dataSort', JSON.stringify( params.tableSort ) );
        setTableSort( params.tableSort )
      }

      if( params.tableFilter ) {
        const tf = JSON.parse( JSON.stringify( params.tableFilter ) );
        tf.conditions = tf.conditions.map( c => {
          if( c.field === null ) {
            c.field = "";
          }

          if( c.value === null ) {
            c.value = "";
          }

          return c;
        });
        
        localStorage.setItem( 'dataFilter', JSON.stringify( tf ) );
        setTableFilter( tf );
      }

      if( params.populationThreshold ) {
        localStorage.setItem( 'populationThreshold', JSON.stringify( params.populationThreshold ) );
        setPopulationThreshold( params.populationThreshold )
      }

      if( params.level ) {
        changeLevel( params.level );
      }

      // always change location, if it's empty = it's Michigan
      changeLocation( params.location ? +params.location : '' );

      if( params.tableWidth ) {
        setTableWidth( +params.tableWidth );
      }

      if( params.mapWidth ) {
        setMapWidth( +params.mapWidth );
      }      

      if( params.maskLocation ) {
        localStorage.setItem( 'maskLocation', params.maskLocation );        
        mapDispatch({ type: 'set-mask', value: params.maskLocation })
      }

      setShowTable( !!params?.showTable );
      setShowMap( !!params?.showMap );
      setShowChart( !!params?.showChart );

      if( params.indexVariables ) {
        localStorage.setItem( 'indexVariables', JSON.stringify( params.indexVariables ) );
        setIndexVariables( params.indexVariables );

        if( sidebarCategoriesOpen?.length && !sidebarCategoriesOpen.includes( '_idx_vars' ) ) {
          toggleSidebarCategory( '_idx_vars' );
        }

        const categoryMaxId = Math.round( +(new Date) / 1000 );
        const _categories = JSON.parse( JSON.stringify( categories.filter( c => c.parent !== 999999 ) ) ),
              _fields = JSON.parse( JSON.stringify( fields ) );

        for( let i = 0; i < params.indexVariables.length; i++ ) {
          const categoryVariable = {
            id: categoryMaxId + i + 1,
            icon: null,
            label: params.indexVariables[i].label,
            name: params.indexVariables[i].name,
            parent: 999999
          };
          _categories.push( categoryVariable );

          
          const fieldVariable = {
            id: params.indexVariables[i].id,
            category_id: categoryMaxId + i + 1,
            label: params.indexVariables[i].label,
            name: params.indexVariables[i].name,
            step: 0.01
          };         
          _fields.push( fieldVariable );
        }

        setCategories( _categories );
        setFields( _fields );
      }
      else {
        console.log( 'empty index variables', params );
      }

      if( params.charts ) {
        chartsDispatch({ type: 'replace', value: params.charts });
      }
    })
    .catch( failed )
    .finally(() => {
      setHashBuildActive(true);
    });

    function failed(){
      // console.log( arguments );
    }
  } // loadHashConfig

  // set current data level
  function changeLevel( value ) {
    setLevel( value );

    if( localStorage ) {
      localStorage.setItem('level', value );
    }
  } // changeLevel

  // set shown location fips/zip code
  function changeLocation( value ) {

    const history = JSON.parse( JSON.stringify(locationHistory) );

    if( value ) {
      history.unshift( value );
    }
    if( history.length > 16 ) {
      history.splice(16);
    }

    const unique = [];
    history.forEach( loc => {
      if( !unique.includes( +loc ) ) {
        unique.push( +loc );
      }
    })

    setLocationHistory( unique );
    setLocation( value );

    if( localStorage ) {
      localStorage.setItem('location', value );
      localStorage.setItem('locationHistory', JSON.stringify( unique ) );
    }


  } // changeLocation 

  function getCategoryChildren( parent, all ){
    const parents = all.filter( i => i.parent === parent );
    parents.sort((a,b) => {
      const aPosition = +(a.position || a.id ),
            bPosition = +(b.position || b.id );

      if( aPosition < bPosition ) return -1;
      else if ( aPosition > bPosition ) return 1;

      return 0;
    });

    return parents.map( p => {
      const item = { ...p };

      const children = getCategoryChildren( p.id, all );

      const itemFields = fields.filter( f => f.category_id === p.id );
      item.fields = itemFields;
      if( itemFields.length ) {
        const all = {
          key: p.name + '#all',
          label: 'All',
          fields: []
        };

        // children.push( all );
        itemFields.forEach( f => {
          const c = { ...f };
          delete c.category;

          c.key = c.name;

          if( !c.label ) {
            c.label = c.name;
          }

          all.fields.push( c.name );

          children.push( c );
        });
      }

      if( children.length ) {
        item.key = 'cat_' + p.name;
        item.children = children;
      }
      else {
        item.key = p.name;
      }

      children.sort((a,b) => {
        const aPosition = +(a.position || a.id ),
              bPosition = +(b.position || b.id );
  
        if( aPosition < bPosition ) return -1;
        else if ( aPosition > bPosition ) return 1;
  
        return 0;
      });

      return item;
    })
  } // getCategoryChildren

  function resize(event){
    if( !isResizing || !resizer.current || !resizeContainer.current ) {
      return;
    }

    const rw = resizer.current.getBoundingClientRect().width,
          rc = resizeContainer.current.getBoundingClientRect(),
          x = event.clientX - rc.left,
          w = rc.width;

    setTableWidth( x / w * 100 );
    setMapWidth( ( w - x ) / w * 100 );   
  } // resize

  function startResizing(){
    setIsResizing( true )
  }

  function endResizing(){
    setIsResizing( false );
    mapDispatch({ type: 'set-reload', value: reloadMap + 1 });
  }  

  function getFips( code ){
    return places.find( p => p.code == code ) || counties.find( c => c.code == code ) || zcta.find( c => c.code == code ) || tracts.find( c => c.code == code );
  }

  function globalClickListener( event ){
    
    // maybe close search suggestion
    if( openLocationSuggestions && !event.target.closest('.search-box') ) {
      setOpenLocationSuggestions( false )
    }
  } // globalClickListener
  


  // toggle sidebar category
  function toggleSidebarCategory( key ) {
    const list = [...sidebarCategoriesOpen];

    if( list.includes( key ) ) {
      // remove
      list.splice( list.indexOf(key), 1 );
    }
    else {
      // add
      list.push( key );
    }

    setSidebarCategoriesOpen( list );

    if( localStorage ) {
      localStorage.setItem('sidebarCategoriesOpen', JSON.stringify(list) );
    }
  } // toggleSidebarCategory

  function getHash(){
    cleanSort();

    if( hashTimeout?.length ) {
      let to;
      while( to = hashTimeout.shift() ) {
        // console.log( 'clear getHash TO', to );
        clearTimeout( to );
      }
    }

    let to = setTimeout( () => {
      // console.log( "doing getHash", to );
      _getHash();
    }, 500 );

    hashTimeout.push( to );

    setHashTimeout( JSON.parse( JSON.stringify( hashTimeout ) ) );
  }

  function _getHash(){ 

    if( !hashBuildActive || isResizing ) {
      return;
    }

    const data = {
      mapBounds,
      columns,
      mapField,
      lockedFips,
      tableSort,
      tableFilter,
      populationThreshold,
      level,
      location,
      maskLocation,
      tableWidth,
      mapWidth,
      indexVariables,
      charts: chartsState,
      showTable,
      showChart,
      showMap
    };

    if( maskLocation ) {
      delete data.mapBounds;
    }

    // get blocks (fields) configuration
    data.blocks = {};
    for( let i = 0; i < columns.length; i++ ) {
      const ls = localStorage?.getItem('blocks@' + columns[i] + '/' + level );
      if( ls ) {
        data.blocks[ columns[i] + '/' + level ] = JSON.parse( ls );
      }
    }

    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'hash';

    if( aborter ) {
      aborter.abort();
    }    

    const ac = new AbortController();
    const signal = ac.signal;

    fetch( url.href, { 
      headers: [
        ['Content-Type', 'application/json']
      ],
      method: "POST", 
      signal,
      body: JSON.stringify( { data } )
    })
    .then( response => response.json() )
    .then( result => {
      if( !result.status ) {
        return failed( result );
      }

      onHashChanged( result.hash );
    })
    .catch( failed )
    .finally(() => {
    });

    setAborter( ac );
  
    function failed(){
      console.log( "Failed" );
    } 
  } // _getHash

  function cleanSort(){
    const newSort = [],
          qty = tableSort.length;

    const tableFields = [...defaultFields, ...columns];
    
    for( let i = 0; i < qty; i++ ) {
      const { sort, field } = tableSort[i];

      const sortField = fields.find( f => f.id === field );
      if( !sortField ) {
        // field doesn't exists
        continue;
      }

      const isInTable = tableFields.find( f => { return ( typeof f === 'string' && f === sortField.name ) || ( f.name === sortField.name ) });
      if( !isInTable ) {
        // field is not in current set of columns
        continue;
      }

      newSort.push( { sort, field } );
    }

    if( newSort.length !== qty ) {
      setTableSort( newSort );
    }   
  } // cleanSort

  function onHashChanged( hash ){
    window.history.pushState( null, null, process.env.PUBLIC_URL + '/' + hash );
    
    setHash( hash );
  }

  useEffect(() => {
    let lssidebarCategoriesOpen = [];
    if( localStorage && localStorage.getItem('sidebarCategoriesOpen') ) {
      try {
        lssidebarCategoriesOpen = JSON.parse( localStorage.getItem('sidebarCategoriesOpen') );
        if( lssidebarCategoriesOpen && Array.isArray(lssidebarCategoriesOpen) ) {
          setSidebarCategoriesOpen( lssidebarCategoriesOpen );
        }
      }
      catch(error){
        localStorage.removeItem('sidebarCategoriesOpen');
      }
    }

    loadConfig();

    window.addEventListener('resize', () => {
      setTimeout( () => mapDispatch({ type: 'set-reload', value: reloadMap + 1 }), 500 );
    });
  }, []);

  useEffect(() => {
    if( fields && fields.length && !configLoaded ) {
      // console.log( "THE FIELDS", JSON.parse( JSON.stringify( fields ) ) );
      setConfigLoaded( true );
    }
  }, [fields]);

  useEffect( () => {
    if( !configLoaded ) {
      return;
    }

    if( hash ) {
      loadHashConfig();      
    }
    else {
      setHashBuildActive(true);
    }  
  }, [configLoaded]);

  useEffect(() => {
    const str = search.toLowerCase();
    const placesList = places.filter( place => place.label.toLowerCase().includes( str ) );
    const countiesList = counties.filter( county => county.label.toLowerCase().includes( str ) );
    setLocationSuggestions( [...placesList,...countiesList] );
  }, [search])

  useEffect( () => {
    getHash();
  }, [
    configLoaded,
    columns,
    lockedFips,
    tableSort,
    tableFilter,
    populationThreshold,
    level,
    location,
    tableWidth,
    mapWidth,
    indexVariables,
    showTable,
    showMap,
    showChart,
    mapBounds,
    maskLocation,
    chartsState
    ]); // get hash 

  useEffect( () => {
    if( !mapField ) {
      mapDispatch({ type: 'set-field', value: 'population' });
    }
  }, [mapField])

  useEffect( () => {
    localStorage?.setItem('showTable', showTable);
    localStorage?.setItem('showMap', showMap);
    localStorage?.setItem('showChart', showChart);

    mapDispatch({ type: 'set-reload', value: reloadMap + 1 });
  }, [showTable,showMap,showChart])

  const columnsList = getCategoryChildren( null, categories );

  const headerHeight = header?.current?.getBoundingClientRect().height ?? 0;

  const allTableFields = [...defaultFields, ...columns];

  return (
    <AppContext.Provider value={{
        title,
        defaultFields,
        allTableFields,
        counties,
        places,
        levels,

        // global list of all fields
        fields,
        setFields,      

        // list of shown fields (and table columns)
        columns,
        toggleColumn,
        tableSort,
        setTableSort,
        tableFilter,
        setTableFilter,      
        categories,
        setCategories,
        statsData,
        setStatsData,
        location,
        changeLocation,      
        locationHistory,

        getHash,

        lockedFips,
        setLockedFips,
        level,
        changeLevel,

        isResizing,
        setIsResizing,
        populationThreshold,
        setPopulationThreshold,      
        getFips,

        // sidebar
        indexVariables,
        setIndexVariables,

        idxVar,
        setIdxVar,

        sidebarCategoriesOpen, 
        setSidebarCategoriesOpen,
        toggleSidebarCategory,
      }}>

      <MapContext.Provider value={{
        ...mapState,
        dispatch: mapDispatch,
        updateMapField
        }}>

        <ChartsContext.Provider value={
              {
                charts: chartsState,
                dispatch: chartsDispatch
              }
            }>
          { !( configLoaded && hashBuildActive ) && <div className="text-xl font-semibold text-center py-16">App is loading...</div> }

          { ( configLoaded && hashBuildActive ) &&
              <div className="flex flex-col h-screen text-sm"
                onClick={ globalClickListener }>

                <header ref={header} className="flex justify-between p-4 flex-none items-center">
                  <div className="text-xl font-semibold">{ title }</div>

                  <div className="flex gap-4 items-center">
                    <button className="flex gap-1 items-center"
                      onClick={() => {
                        setShowTable( !showTable );
                      }}> 
                        Data Table
                      <ToggleIcon state={ showTable } />
                    </button>

                    <button className="flex gap-1 items-center"
                      onClick={() => {
                        setShowMap( !showMap );
                      }}>
                        Map
                      <ToggleIcon state={ showMap } />
                    </button>

                    <button className="flex gap-1 items-center"
                      onClick={() => {
                        setShowChart( !showChart );
                      }}>
                        Charts
                      <ToggleIcon state={ showChart } />
                    </button>
                  </div>

                  <div className="flex gap-4">
                    <button className="text-black/30"
                      onClick={() => { localStorage.clear(); window.location.assign( process.env.PUBLIC_URL ) } }>reset map</button>

                    <div className="search-box relative" 
                          onMouseEnter={ () => setOpenLocationSuggestions(true) }>
                      <input className="search-input" 
                              type="search" 
                              name="search" 
                              placeholder="Search location..." 
                              value={search} 
                              onFocus={() => setOpenLocationSuggestions(true) }
                              onInput={(event) => setSearch( event.target.value ) } 
                              />

                      { openLocationSuggestions && <div className="search-results">
                        { locationSuggestions.map( item => (
                            <button onClick={ () => {
                                  mapDispatch({ type: 'set-recenter', value: true });
                                  mapDispatch({ type: 'set-mask', value: true });
                                  
                                  changeLocation( item.code);
                                  setOpenLocationSuggestions( false );
                                  setSearch( "" );
                                }
                              } 
                              key={ item.code }
                              className="search-result"
                              disabled={ item.code == location }
                              >

                              <Icon name='location-dot' />

                              { item.code == location && 
                                <span className="inline-block text-xs leading-normal text-white bg-slate-400 px-1 rounded align-middle">
                                  current
                                </span> }

                              { item.label }
                            </button>
                          )
                        )}
                      </div> }
                    </div>
                  </div>
                </header>

                <div className="flex flex-col lg:flex-row flex-auto 
                                relative
                                w-full max-w-full 
                                border-t border-slate-300 gap-4">

                  <div className={ cn( { '--hidden': !columnsPanel }, 'filter' ) }
                    style={ { height: `calc( 100vh - ${headerHeight}px - 1px )` }}>
                    <div className={ cn( { "hidden": !columnsPanel }, "flex flex-col gap-4 justify-between h-full overflow-auto pr-4 -mr-4 scrollbar" ) }>
                      <Sidebar list={ columnsList } />

                      <IndexVariableManager />
                    </div>

                    <button className="--close text-xs" onClick={ () => {
                        setColumnsPanel( !columnsPanel );
                        setTimeout( () => {
                          mapDispatch({ type: 'set-reload', value: reloadMap + 1 });
                        }, 1000 );
                      }}>
                      { columnsPanel ? <Icon name='angle-left' /> :  <Icon name='angle-right' />}
                    </button>
                  </div>

                  <div className="resize-container" 
                        ref={resizeContainer}
                        onMouseMove={ resize }  
                        onMouseUp={ endResizing }>

                    <DataTableContainer width={tableWidth} show={showTable} />
                    

                    <div className="resizer" ref={resizer}>
                      <div className="--indicator"></div>

                      <button onMouseDown={ startResizing }
                            >
                        <Icon name='arrows-left-right' /> 
                      </button>
                    </div>

                    <MapChartsContainer width={ mapWidth } table={ showTable } map={ showMap } charts={ showChart } />
                  </div>
                </div>
              </div>
          }
        </ChartsContext.Provider>
      </MapContext.Provider>
    </AppContext.Provider>
  );
}

export default App;

